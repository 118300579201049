import request from '@/utils/request';
export var createLink = function createLink(data) {
  return request({
    url: '/agents/createLink',
    method: 'post',
    data: data
  });
};
export var getAgents = function getAgents(data) {
  return request({
    url: '/agents/list',
    method: 'get',
    params: data
  });
};
export var getAgentsBySearch = function getAgentsBySearch(data) {
  return request({
    url: '/agents/query',
    method: 'get',
    params: data
  });
};
export var agentActive = function agentActive(data) {
  return request({
    url: '/agents',
    method: 'post',
    data: data
  });
};
export var agentRecharge = function agentRecharge(data) {
  return request({
    url: '/agents/recharge',
    method: 'post',
    data: data
  });
};
export var getChargeList = function getChargeList(data) {
  return request({
    url: '/agents/paylist/' + data.id,
    method: 'get'
  });
};
export var getChargeCardList = function getChargeCardList(data) {
  return request({
    url: '/agents/chargelist/' + data.id,
    method: 'get'
  });
};
export var getSellList = function getSellList(data) {
  return request({
    url: '/agents/sellList/' + data.id,
    method: 'get',
    params: data
  });
};